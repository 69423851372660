// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './dertouristik.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/DerTouristik/DerTouristik.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage src="../../../images/casestudy/dertouristik/heroimg.png" alt="" objectFit="contain" className="image" />
)
const welcomeImg = (
  <StaticImage src="../../../images/casestudy/dertouristik/welcome.png" alt="" objectFit="contain" className="image" />
)
const infoImg = (
  <StaticImage src="../../../images/casestudy/dertouristik/wls.png" alt="" objectFit="contain" className="image" />
)
const func1Img = (
  <StaticImage src="../../../images/casestudy/dertouristik/func1.png" alt="" objectFit="contain" className="image" />
)
const func2Img = (
  <StaticImage src="../../../images/casestudy/dertouristik/func2.png" alt="" objectFit="contain" className="image" />
)
const func3Img = (
  <StaticImage src="../../../images/casestudy/dertouristik/func3.png" alt="" objectFit="contain" className="image" />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/dertouristik/tech.png" alt="" objectFit="contain" className="image" />
)
const advantagesImg = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/advantages.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback01 = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/customer-01.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback02 = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/customer-02.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const feedback03 = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/customer-03.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

// wls logos
const kuoni = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/kuoni.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const lastminute = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/lastminute.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const helvetictours = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/helvetictours.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const mantareisen = (
  <StaticImage
    src="../../../images/casestudy/dertouristik/wls/mantareisen.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  name: 'Kuoni',
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.dertouristik.title,
    description: projectsList.dertouristik.description
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    title: caseMessages.welcomeTitle,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  logosSlider: {
    title: caseMessages.logosSliderTitle,
    list: [
      {
        link: '',
        logo: kuoni
      },
      {
        link: '',
        logo: lastminute
      },
      {
        link: '',
        logo: helvetictours
      },
      {
        link: '',
        logo: mantareisen
      }
    ]
  },
  info: {
    img: infoImg,
    title: caseMessages.infoTitle,
    list: [caseMessages.infoItem1, caseMessages.infoItem2, caseMessages.infoItem3]
  },
  stats: [
    {
      number: 110,
      positive: true,
      numberDimension: dimensionMessages.dimensionYears,
      value: caseMessages.stat1Value,
      color: 'tertiary',
      icon: 'icon-star-filled'
    },
    {
      number: 123,
      numberDimension: '',
      value: caseMessages.stat2Value,
      color: 'success',
      icon: 'icon-beach-access'
    },
    {
      number: 644,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat3Value,
      color: 'primary',
      icon: 'icon-euro'
    },
    {
      numberOverride: '24/7',
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-clock'
    }
  ],
  functionalitiesTitle: caseMessages.featuresTitle,
  functionalitiesListInversed: true,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [caseMessages.functionality1Item1, caseMessages.functionality1Item2, caseMessages.functionality1Item3],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [
        caseMessages.functionality2Item1,
        caseMessages.functionality2Item2,
        caseMessages.functionality2Item3,
        caseMessages.functionality2Item4
      ],
      img: func2Img
    },
    {
      title: caseMessages.functionality3Title,
      list: [caseMessages.functionality3Item1, caseMessages.functionality3Item2, caseMessages.functionality3Item3],
      img: func3Img
    }
  ],
  roadmapTitle: baseCaseMessages.workflow,
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: caseMessages.featuresDoneTitle,
    tech: {
      title: caseMessages.techTitle,
      list: [caseMessages.tech1, caseMessages.tech2, caseMessages.tech3, caseMessages.tech4],
      img: techImg
    },
    advantages: {
      title: caseMessages.advantagesTitle,
      list: [caseMessages.advantage1, caseMessages.advantage2, caseMessages.advantage3],
      img: advantagesImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: caseMessages.designText,
      list: [caseMessages.design1, caseMessages.design2],
      img: projectsList.dertouristik.overviewImage2
    }
  },
  reviews: [
    {
      img: feedback01,
      name: 'Jana',
      from: 'Trustpilot',
      titleId: caseMessages.reviewTitle1,
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      textId: caseMessages.reviewText1
    },
    {
      img: feedback02,
      name: 'Peter K.',
      from: 'Google Review',
      titleId: caseMessages.reviewTitle2,
      rating: [1, 2, 3, 4, 5],
      // eslint-disable-next-line max-len
      textId: caseMessages.reviewText2
    },
    {
      img: feedback03,
      name: 'Hannah',
      from: 'erfahrungen24',
      titleId: caseMessages.reviewTitle3,
      rating: [1, 2, 3, 4, 5],
      textId: caseMessages.reviewText3
    }
  ]
}

const CaseLHH = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyDER)}
        description={formatMessage(metaDescription.CaseStudyDerDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseLHH)
